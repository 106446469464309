import { isServer } from "@utils/utils";

export interface UserPassword {
  provider?: string;
  password: string;
  resetPasswordToken?: string;
  confirmationToken?: string;
}

export class UserPasswordImpl implements UserPassword {
  private _provider: string;
  private _password: string;
  private _resetPasswordToken: string;
  private _confirmationToken: string;

  constructor(userPassword?: UserPassword) {
    this._provider = userPassword?.provider || "";
    this._password = userPassword?.password || "";
    this._resetPasswordToken = userPassword?.resetPasswordToken || "";
    this._confirmationToken = userPassword?.confirmationToken || "";
  }

  get provider(): string {
    return this._provider;
  }

  set provider(provider: string) {
    this._provider = provider;
  }

  get password(): string {
    if (isServer()) {
      // TODO verify security of this method on client side (isServer() is true on server side)
      return this._password;
    }
    return "";
  }

  set password(password: string) {
    this._password = password;
  }

  get resetPasswordToken(): string {
    return this._resetPasswordToken;
  }

  set resetPasswordToken(resetPasswordToken: string) {
    this._resetPasswordToken = resetPasswordToken;
  }

  get confirmationToken(): string {
    return this._confirmationToken;
  }

  set confirmationToken(confirmationToken: string) {
    this._confirmationToken = confirmationToken;
  }
}
