import { Company } from "@constants/enums";

export const isServer = () => {
  return typeof window === "undefined";
};
export const includesOnepoleThenSlice = (name: string) => {
  if (name.toLowerCase().includes(Company.name)) {
    return name.slice(Company.name.length);
  }
  return name;
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};
