import { Order } from "@utils/Order/Order";
import { UserPassword, UserPasswordImpl } from "@utils/User/UserPassword";
import { Customer, CustomerImpl, CustomerJSONObject } from "@utils/Customer/Customer";
import { Address, AddressJSONObject } from "@utils/Address/Address";
import { CountryName } from "@utils/Country/countryEnums";
import { Country } from "@utils/Country/Country";
import { UserData } from "@utils/User/UserRepository";
import { makeAutoObservable } from "mobx";
import { useCountryContext } from "@utils/Country/useCountryContext";
import { CreateUserData } from "@utils/Auth/AuthRepository";
import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import { Retailer, RetailerImpl, RetailerJSONObject } from "@utils/Retailer/Retailer";

export enum RoleName {
  Admin = "Admin",
  User = "User",
  Guest = "Guest"
}

export interface UserInformation {
  name: string;
  phoneNumber?: string;
  email?: string;
  addresses: Address[];
  company: string;
  country: Country;
}

export interface Role {
  id?: number;
  name: RoleName;
  description?: string;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface UserJSONObject {
  id?: number;
  username?: string;
  email?: string;
  phoneNumber?: string;
  name: string;
  company: string;
  addresses: AddressJSONObject[];
  role: RoleName;
  blocked: boolean;
  country: CountryName;
  customer?: CustomerJSONObject;
  defaultRetailer?: RetailerJSONObject;
}

export interface User extends UserInformation {
  id: number;
  username: string;
  login: string;
  password?: UserPassword;
  role: RoleName;
  blocked: boolean;
  orders?: Order[];
  createdAt: string;
  updatedAt: string;
  customerProfile?: Customer;
  defaultRetailer?: Retailer;
  priceLevel: number;
  discount: number;

  update(data: UserJSONObject): Promise<UserData | undefined>;

  updateFromData(data: UserData): void;

  toCreateUserData(): CreateUserData;

  toJSON(): UserJSONObject;
}

export class UserImpl implements User {
  id: number;
  username: string;
  login: string;
  password?: UserPassword;
  name: string;
  phoneNumber?: string;
  email?: string;
  addresses: Address[];
  company: string;
  country: Country;
  role: RoleName;
  blocked: boolean;
  orders?: Order[];
  customerProfile?: Customer;
  defaultRetailer?: Retailer;
  createdAt: string;
  updatedAt: string;

  constructor({
    id,
    username,
    email,
    password,
    name,
    phoneNumber,
    addresses,
    company,
    country,
    role,
    blocked,
    createdAt,
    updatedAt,
    customer,
    defaultRetailer
  }: UserData) {
    makeAutoObservable(this);

    const { countryManager } = useCountryContext();

    this.id = id || 0;
    this.username = username || "";
    this.login = email || "";
    this.password = password ? new UserPasswordImpl({ password: password }) : undefined;
    this.name = name || "";
    this.phoneNumber = phoneNumber || "";
    this.addresses = addresses || [];
    this.company = company || "";
    this.country = countryManager.getCountryByName(country || CountryName.Norway) || countryManager.defaultCountry;
    this.role = role?.name || RoleName.Guest;
    this.blocked = blocked || false;
    this.orders = undefined;
    this.createdAt = createdAt || "";
    this.updatedAt = updatedAt || "";
    this.customerProfile = customer ? new CustomerImpl(customer) : undefined;
    this.defaultRetailer = defaultRetailer ? new RetailerImpl(defaultRetailer) : undefined;
  }

  get priceLevel(): number {
    return this.customerProfile?.price_type?.priceLevel || 0;
  }

  set priceLevel(priceLevel: number) {
    if (this.customerProfile?.price_type) {
      this.customerProfile.price_type.priceLevel = priceLevel;
    }
  }

  get discount(): number {
    return this.customerProfile?.discount || 0;
  }

  async update(user: UserJSONObject): Promise<UserData | undefined> {
    // TODO delete when profile page redesign is done
    try {
      const { data } = await axios.post(`/api/user/update_me`, {
        user
      });

      return data;
    } catch (e) {
      console.log("e: ", e);
      Sentry.captureException(e);
      return;
    }
  }

  updateFromData(user: UserData) {
    const { countryManager } = useCountryContext();

    this.id = user.id || this.id;
    this.username = user.username || this.username;
    this.login = user.email || this.login;
    this.password = undefined;
    this.name = user.name || this.name;
    this.phoneNumber = user.phoneNumber || this.phoneNumber;
    this.addresses = user.addresses || this.addresses;
    this.company = user.company || this.company;
    this.country =
      countryManager.getCountryByName((user.country as CountryName) || CountryName.Norway) ||
      countryManager.defaultCountry;
    this.role = user.role?.name || this.role;
    this.blocked = user.blocked || this.blocked;
    this.orders = undefined;
    this.createdAt = user.createdAt || this.createdAt;
    this.updatedAt = user.updatedAt || this.updatedAt;
    this.customerProfile = user.customer ? new CustomerImpl(user.customer) : this.customerProfile;
  }

  toCreateUserData(): CreateUserData {
    return {
      username: this.username,
      email: this.login,
      password: this.password?.password || "",
      phoneNumber: this.phoneNumber,
      name: this.name,
      company: this.company,
      addresses: this.addresses,
      country: this.country.name
    };
  }

  toJSON(): UserJSONObject {
    const data: UserJSONObject = {
      id: this.id,
      username: this.username,
      email: this.login,
      phoneNumber: this.phoneNumber,
      name: this.name,
      company: this.company,
      addresses: this.addresses.map((address) => address.toJSON()),
      role: this.role,
      blocked: this.blocked,
      country: this.country.name
    };

    if (this.customerProfile) {
      data.customer = this.customerProfile.toJSON();
    }
    if (this.defaultRetailer) {
      data.defaultRetailer = this.defaultRetailer.toJSON();
    }

    return data;
  }
}
