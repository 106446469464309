import { PriceTypeInterface } from "@constants/interfaces";
import { UserData } from "@utils/User/UserRepository";
import { Retailer, RetailerImpl, RetailerJSONObject } from "@utils/Retailer/Retailer";
import { RetailerData } from "@utils/Retailer/RetailerRepository";

export interface CustomerData {
  id?: number;
  retailers?: RetailerData[];
  user?: UserData;
  price_type: PriceTypeInterface;
  discount: number;
  showPrices: boolean;
  showRetailers?: boolean;
}

export interface Customer {
  id?: number;
  retailers?: Retailer[];
  user?: UserData;
  price_type: PriceTypeInterface;
  discount: number;
  showPrices: boolean;
  showRetailers?: boolean;

  toJSON(): CustomerJSONObject;
}

export interface CustomerJSONObject {
  id?: number;
  retailers?: RetailerJSONObject[];
  user?: UserData;
  price_type: PriceTypeInterface;
  discount: number;
  showPrices: boolean;
  showRetailers?: boolean;
}

export class CustomerImpl implements Customer {
  id?: number;
  retailers: Retailer[];
  user?: UserData;
  price_type: PriceTypeInterface;
  discount: number;
  showPrices: boolean;
  showRetailers?: boolean;

  constructor(customer?: {
    id?: number;
    retailers?: RetailerData[];
    user?: UserData;
    price_type: PriceTypeInterface;
    discount: number;
    showPrices: boolean;
    showRetailers?: boolean;
  }) {
    this.retailers = customer?.retailers?.map((retailer) => new RetailerImpl(retailer)) || [];
    this.user = customer?.user || undefined;
    this.price_type = customer?.price_type || { id: 0, name: "", priceLevel: 0 };
    this.discount = customer?.discount || 0;
    this.showPrices = customer?.showPrices || false;
    this.showRetailers = customer?.showRetailers || false;
  }

  toJSON(): CustomerJSONObject {
    return {
      retailers: this.retailers.map((retailer) => retailer.toJSON()),
      user: this.user,
      price_type: this.price_type,
      discount: this.discount,
      showPrices: this.showPrices,
      showRetailers: this.showRetailers
    };
  }
}
