import {
  CountryCode,
  CountryName,
  CurrencyCode,
  CurrencySide,
  DomainExtension,
  LanguageName,
  LocaleCode
} from "@utils/Country/countryEnums";

import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import norwegianBokmal from "i18n-iso-countries/langs/nb.json";
import german from "i18n-iso-countries/langs/de.json";

countries.registerLocale(english);
countries.registerLocale(norwegianBokmal);
countries.registerLocale(german);

export interface CountryInformation {
  name: CountryName;
  language: LanguageName;
  domain: DomainExtension;
  locale: LocaleCode;
  countryCode: CountryCode;
  currency: CurrencyCode;
  currencyPlacement: CurrencySide;
  currencySymbol: string;
  hasLocale: boolean;
}

export interface Country extends CountryInformation {
  isCountryName(name: CountryName): boolean;

  isLanguageName(language: LanguageName): boolean;

  isDomainExtension(domain: DomainExtension): boolean;

  isLocaleCode(locale: LocaleCode): boolean;

  isCountryCode(country: CountryCode): boolean;

  isCurrencyCode(currency: CurrencyCode): boolean;

  isCurrencySymbol(currencySymbol: string): boolean;

  isCurrencyPlacement(currencyPlacement: CurrencySide): boolean;

  formatCurrency(amount: number): string;

  formatDate(date: Date): string;

  formatTime(date: Date): string;

  getLocaleCode(): LocaleCode;

  getLocalizedCountryName(locale: LocaleCode): string | undefined;

  getLocalizedLanguageName(locale: LocaleCode): string;

  isValidPhoneNumber(phoneNumber: string): boolean;

  isValidPostalCode(postalCode: string): boolean;

  isValidVatNumber(vatNumber: string): boolean;

  onDataChange?: () => void;

  toJSON(): CountryInformation;
}

// Country Implementation
export class CountryImpl implements Country {
  readonly name: CountryName;
  readonly language: LanguageName;
  readonly domain: DomainExtension;
  readonly locale: LocaleCode;
  readonly countryCode: CountryCode;
  readonly currency: CurrencyCode;
  readonly currencyPlacement: CurrencySide;
  readonly currencySymbol: string;
  readonly hasLocale: boolean;
  onDataChange?: () => void;

  constructor(countryInfo: CountryInformation, onDataChange?: () => void) {
    this.name = countryInfo.name;
    this.language = countryInfo.language;
    this.domain = countryInfo.domain;
    this.locale = countryInfo.locale;
    this.countryCode = countryInfo.countryCode;
    this.currency = countryInfo.currency;
    this.currencyPlacement = countryInfo.currencyPlacement;
    this.currencySymbol = countryInfo.currencySymbol;
    this.hasLocale = countryInfo.hasLocale;
    this.onDataChange = onDataChange;

    this.onDataChange?.();
  }

  isCountryName(name: CountryName): boolean {
    return this.name === name;
  }

  isLanguageName(language: LanguageName): boolean {
    return this.language === language;
  }

  isDomainExtension(domain: DomainExtension): boolean {
    return this.domain === domain;
  }

  isLocaleCode(locale: LocaleCode): boolean {
    return this.locale === locale;
  }

  isCountryCode(country: CountryCode): boolean {
    return this.countryCode === country;
  }

  isCurrencyCode(currency: CurrencyCode): boolean {
    return this.currency === currency;
  }

  isCurrencySymbol(currencySymbol: string): boolean {
    return this.currencySymbol === currencySymbol;
  }

  isCurrencyPlacement(currencyPlacement: CurrencySide): boolean {
    return this.currencyPlacement === currencyPlacement;
  }

  formatCurrency(amount: number): string {
    // Example implementation, you can modify it based on your requirements
    return new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: this.currency
    }).format(amount);
  }

  formatDate(date: Date): string {
    // Example implementation, you can modify it based on your requirements
    return new Intl.DateTimeFormat(this.locale).format(date);
  }

  formatTime(date: Date): string {
    return new Intl.DateTimeFormat(this.locale, {
      timeStyle: "medium"
    }).format(date);
  }

  getLocaleCode(): LocaleCode {
    // Check if the locale is in LocaleCode enum
    if (Object.values(LocaleCode).includes(this.locale)) {
      return this.locale.split("-")[0] as LocaleCode;
    } else {
      // If not, return the default locale
      return LocaleCode.UnitedKingdom.split("-")[0] as LocaleCode;
    }
  }

  getLocalizedCountryName(locale: LocaleCode): string | undefined {
    return countries.getName(this.countryCode, locale);
  }

  getLocalizedLanguageName(locale: LocaleCode): string {
    return countries.getNames(locale)[this.language];
  }

  isValidPhoneNumber(phoneNumber: string): boolean {
    throw new Error(`Method not implemented. ${phoneNumber}}`);
    // Implement phone number validation for the specific country.
    // You can use an external library or an API for validation.
    // This is just a placeholder and should be replaced with a proper implementation.
    return false;
  }

  isValidPostalCode(postalCode: string): boolean {
    throw new Error(`Method not implemented. ${postalCode}}`);
    // Implement postal code validation for the specific country.
    // You can use an external library or an API for validation.
    // This is just a placeholder and should be replaced with a proper implementation.
    return false;
  }

  isValidVatNumber(vatNumber: string): boolean {
    throw new Error(`Method not implemented. ${vatNumber}}`);
    // Implement VAT number validation for the specific country.
    // You can use an external library or an API for validation.
    // This is just a placeholder and should be replaced with a proper implementation.
    return false;
  }

  toJSON(): CountryInformation {
    return {
      name: this.name,
      language: this.language,
      domain: this.domain,
      locale: this.locale,
      countryCode: this.countryCode,
      currency: this.currency,
      currencyPlacement: this.currencyPlacement,
      currencySymbol: this.currencySymbol,
      hasLocale: this.hasLocale
    };
  }
}
