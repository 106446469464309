import { Country, CountryImpl } from "./Country";
import { countryList } from "@utils/Country/countryList";
import {
  CountryCode,
  CountryName,
  CurrencyCode,
  DomainExtension,
  LanguageName,
  LocaleCode
} from "@utils/Country/countryEnums";

export interface CountryManager {
  getCountryByName(name: CountryName): Country | undefined;

  getCountryByLanguage(language: LanguageName): Country | undefined;

  getCountryByDomain(domain: DomainExtension): Country | undefined;

  getCountryByLocale(locale: LocaleCode): Country | undefined;

  getCountryByCountryCode(countryCode: CountryCode): Country | undefined;

  getCountryByCurrency(currency: CurrencyCode): Country | undefined;

  getAllCountries(): Country[];

  getAllCountriesWithLocale(): Country[];

  defaultCountry: Country;
}

export class CountryManagerImpl implements CountryManager {
  private readonly _countries: Country[];

  constructor() {
    this._countries = countryList.map((country) => new CountryImpl(country));
  }

  getCountryByName(name: CountryName): Country | undefined {
    return this._countries.find((country) => country.name === name);
  }

  getCountryByLanguage(language: LanguageName): Country | undefined {
    return this._countries.find((country) => country.language === language);
  }

  getCountryByDomain(domain: DomainExtension): Country | undefined {
    return this._countries.find((country) => country.domain === domain);
  }

  getCountryByLocale(locale: LocaleCode): Country | undefined {
    return this._countries.find((country) => country.hasLocale && country.locale === locale);
  }

  getCountryByCountryCode(countryCode: CountryCode): Country | undefined {
    return this._countries.find((country) => country.countryCode === countryCode);
  }

  getCountryByCurrency(currency: CurrencyCode): Country | undefined {
    return this._countries.find((country) => country.currency === currency);
  }

  getAllCountries(): Country[] {
    return this._countries;
  }

  getAllCountriesWithLocale(): Country[] {
    return this._countries.filter((country) => country.hasLocale);
  }

  get defaultCountry(): Country {
    const norway = this.getCountryByName(CountryName.Norway);

    if (norway) {
      return norway;
    }
    return this._countries[0];
  }
}
