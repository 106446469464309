"use client";

import { parseCookies, setCookie } from "nookies";
import { createContext, useEffect, useState } from "react";

import { User, UserImpl, UserJSONObject } from "@utils/User/User";
import { UserData } from "@utils/User/UserRepository";
import { CustomerData } from "@utils/Customer/Customer";
import { AuthRepositoryImpl } from "@utils/Auth/AuthRepository";
import { RetailerData } from "@utils/Retailer/RetailerRepository";

export interface UserContextInterface {
  user: User | null;
  login: (user: UserData) => void;
  logout: () => Promise<void>;
}

export const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

export const UserProvider = ({ children }: { children?: JSX.Element | JSX.Element[] }) => {
  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (user) return;

    const cookies = parseCookies();
    if (cookies?.user) {
      const userData: UserJSONObject = JSON.parse(cookies.user);
      const newUser = new UserImpl({
        id: userData.id,
        username: userData.username,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        name: userData.name,
        company: userData.company,
        role: {
          name: userData.role
        },
        blocked: userData.blocked,
        country: userData.country,
        customer: userData.customer as CustomerData,
        defaultRetailer: userData.defaultRetailer as RetailerData
      });

      setUser(newUser);
    }
  }, []);

  // Login updates the user data with a name parameter
  const login = (user: UserData) => {
    const newUser = new UserImpl(user);
    setUser(newUser); // Set user cookie
    setCookie(null, "user", JSON.stringify(newUser.toJSON()), {
      secure: process.env.NODE_ENV !== "development",
      maxAge: 24 * 60 * 60,
      path: "/"
    });
  };

  // Logout updates the user data to default
  const logout = async () => {
    const authRepository = new AuthRepositoryImpl();
    await authRepository.logout();

    setUser(null);
  };

  return <UserContext.Provider value={{ user, login, logout }}>{children}</UserContext.Provider>;
};
