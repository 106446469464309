import { Customer, CustomerImpl } from "@utils/Customer/Customer";
import { DiscountData } from "@utils/Discount/DiscountRepository";
import { OrderData } from "@utils/Order/OrderRepository";
import { PriceTypeInterface } from "@constants/interfaces";
import { LocationData, RetailerData } from "@utils/Retailer/RetailerRepository";
import { makeAutoObservable } from "mobx";

export interface RetailerJSONObject {
  id: number;
  name: string;
  price_type?: PriceTypeInterface;
  showPrice?: boolean;
  hidden?: boolean;
  forceDiscountCode?: boolean;
  location?: LocationData;
}

export interface Retailer extends RetailerData {
  get id(): number;

  get name(): string;

  get customers(): Customer[] | undefined;

  get discounts(): DiscountData[] | undefined;

  get price_type(): PriceTypeInterface | undefined;

  get orders(): OrderData[] | undefined;

  get showPrice(): boolean;

  get hidden(): boolean;

  get forceDiscountCode(): boolean;

  get location(): LocationData | undefined;

  toJSON(): RetailerJSONObject;
}

export class RetailerImpl implements Retailer {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _customers?: Customer[];
  private readonly _discounts?: DiscountData[];
  private readonly _price_type?: PriceTypeInterface;
  private readonly _orders?: OrderData[];
  private readonly _showPrice: boolean;
  private readonly _hidden: boolean;
  private readonly _forceDiscountCode: boolean;
  private readonly _location: LocationData | undefined;

  constructor(retailer: RetailerData) {
    makeAutoObservable(this);
    this._id = retailer.id;
    this._name = retailer.name;
    this._customers = retailer.customers?.map((customer) => new CustomerImpl(customer));
    this._discounts = retailer.discounts;
    this._price_type = retailer.price_type;
    this._orders = retailer.orders;
    this._showPrice = retailer.showPrice || false;
    this._hidden = retailer.hidden || false;
    this._forceDiscountCode = retailer.forceDiscountCode || false;
    this._location = retailer.location;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get customers(): Customer[] | undefined {
    return this._customers;
  }

  get discounts(): DiscountData[] | undefined {
    return this._discounts;
  }

  get price_type(): PriceTypeInterface | undefined {
    return this._price_type;
  }

  get orders(): OrderData[] | undefined {
    return this._orders;
  }

  get showPrice(): boolean {
    return this._showPrice;
  }

  get hidden(): boolean {
    return this._hidden;
  }

  get forceDiscountCode(): boolean {
    return this._forceDiscountCode;
  }

  get location(): LocationData | undefined {
    return this._location;
  }

  toJSON(): RetailerJSONObject {
    return {
      id: this.id,
      name: this.name,
      price_type: this.price_type,
      showPrice: this.showPrice,
      hidden: this.hidden,
      forceDiscountCode: this.forceDiscountCode
    };
  }
}
