import * as Sentry from "@sentry/nextjs";
import { UserData } from "@utils/User/UserRepository";
import { CountryName } from "@utils/Country/countryEnums";
import { Address } from "@utils/Address/Address";
import { ResponseStatus } from "@utils/email/EmailHandler";

export interface UserLogin {
  identifier: string;
  password: string;
}

export interface CreateUserData {
  username?: string;
  email?: string | undefined;
  phoneNumber?: string;
  password: string;
  name: string;
  country?: CountryName;
  addresses?: Address[];
  company?: string;
  subscribeToNewsletter?: boolean;
}

export interface UserResponse {
  status: ResponseStatus;
  data: UserData | null;
  error?: {
    message: string;
  };
}

export interface AuthRepository {
  login(data: UserLogin): Promise<UserResponse | undefined>;

  register(user: CreateUserData): Promise<UserResponse | undefined>;

  logout(): Promise<void>;
}

export class AuthRepositoryImpl implements AuthRepository {
  _jwt: string | undefined;

  constructor(jwt?: string) {
    this._jwt = jwt;
  }

  async login(user: UserLogin): Promise<UserResponse | undefined> {
    try {
      const response = await fetch(`/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          identifier: user.identifier,
          password: user.password
        })
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      return await response.json();
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  async logout(): Promise<void> {
    try {
      const response = await fetch(`/api/auth/logout`, {
        method: "POST"
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  async register(user: CreateUserData): Promise<UserResponse | undefined> {
    try {
      const response = await fetch(`/api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
      });

      if (!response.ok) {
        throw new Error("Registration failed");
      }

      return await response.json();
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }
}
